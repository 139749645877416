import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Card, StyledCard } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Card" title="Card" mdxType="PageTitle" />
    <h2 {...{
      "id": "inactive-card"
    }}>{`Inactive card`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Card>\n    <Rhythm p={3}>Hello world</Rhythm>\n  </Card>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Card,
      StyledCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Card mdxType="Card">
      <Rhythm p={3} mdxType="Rhythm">Hello world</Rhythm>
    </Card>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "bordered-card"
    }}>{`Bordered card`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Card bordered>\n    <Rhythm p={3}>Hello world</Rhythm>\n  </Card>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Card,
      StyledCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Card bordered mdxType="Card">
      <Rhythm p={3} mdxType="Rhythm">Hello world</Rhythm>
    </Card>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "bordered-and-hoverable-card"
    }}>{`Bordered and hoverable card`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Card bordered hoverable>\n    <Rhythm p={3}>Hello world</Rhythm>\n  </Card>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Card,
      StyledCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Card bordered hoverable mdxType="Card">
      <Rhythm p={3} mdxType="Rhythm">Hello world</Rhythm>
    </Card>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "raised-card"
    }}>{`Raised card`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Card raised>\n    <Rhythm p={3}>Hello world</Rhythm>\n  </Card>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Card,
      StyledCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Card raised mdxType="Card">
      <Rhythm p={3} mdxType="Rhythm">Hello world</Rhythm>
    </Card>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "raised-and-hoverable-card"
    }}>{`Raised and hoverable card`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Card hoverable raised>\n    <Rhythm p={3}>Hello world</Rhythm>\n  </Card>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Card,
      StyledCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Card hoverable raised mdxType="Card">
      <Rhythm p={3} mdxType="Rhythm">Hello world</Rhythm>
    </Card>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "raised-custom-levels"
    }}>{`Raised custom levels`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Rhythm my={3}>\n    <Card hoverable raised={10}>\n      <Rhythm p={3}>Hello world (10)</Rhythm>\n    </Card>\n    <Card hoverable raised={20}>\n      <Rhythm p={3}>Hello world (20)</Rhythm>\n    </Card>\n    <Card hoverable raised={30}>\n      <Rhythm p={3}>Hello world (30)</Rhythm>\n    </Card>\n    <Card hoverable raised={40}>\n      <Rhythm p={3}>Hello world (40)</Rhythm>\n    </Card>\n    <Card hoverable raised={100}>\n      <Rhythm p={3}>Hello world (100)</Rhythm>\n    </Card>\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Card,
      StyledCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm my={3} mdxType="Rhythm">
      <Card hoverable raised={10} mdxType="Card">
        <Rhythm p={3} mdxType="Rhythm">Hello world (10)</Rhythm>
      </Card>
      <Card hoverable raised={20} mdxType="Card">
        <Rhythm p={3} mdxType="Rhythm">Hello world (20)</Rhythm>
      </Card>
      <Card hoverable raised={30} mdxType="Card">
        <Rhythm p={3} mdxType="Rhythm">Hello world (30)</Rhythm>
      </Card>
      <Card hoverable raised={40} mdxType="Card">
        <Rhythm p={3} mdxType="Rhythm">Hello world (40)</Rhythm>
      </Card>
      <Card hoverable raised={100} mdxType="Card">
        <Rhythm p={3} mdxType="Rhythm">Hello world (100)</Rhythm>
      </Card>
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "squared-card"
    }}>{`Squared card`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Card hoverable raised squared>\n    <Rhythm p={3}>Hello world</Rhythm>\n  </Card>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Card,
      StyledCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Card hoverable raised squared mdxType="Card">
      <Rhythm p={3} mdxType="Rhythm">Hello world</Rhythm>
    </Card>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "magnification"
    }}>{`Magnification`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <div style={{ width: \'300px\', height: \'200px\' }}>\n    <Card full hoverable magnify raised>\n      <Rhythm p={3}>Hello world</Rhythm>\n    </Card>\n  </div>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Card,
      StyledCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <div style={{
          width: '300px',
          height: '200px'
        }}>
      <Card full hoverable magnify raised mdxType="Card">
        <Rhythm p={3} mdxType="Rhythm">Hello world</Rhythm>
      </Card>
    </div>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "styled-bordered-and-hoverable-card"
    }}>{`Styled bordered and hoverable card`}</h3>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <StyledCard\n    bordered\n    hoverable\n    borderColor=\"#556270\"\n    hoveredBorderColor=\"#393945\"\n  >\n    <Rhythm p={3}>Hello world</Rhythm>\n  </StyledCard>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Card,
      StyledCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StyledCard bordered hoverable borderColor="#556270" hoveredBorderColor="#393945" mdxType="StyledCard">
      <Rhythm p={3} mdxType="Rhythm">Hello world</Rhythm>
    </StyledCard>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "card"
    }}>{`Card`}</h3>
    <Props of={Card} mdxType="Props" />
    <h3 {...{
      "id": "styledcard"
    }}>{`StyledCard`}</h3>
    <Props of={StyledCard} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      